import { css } from "@emotion/react"
import { Link } from "gatsby"
import React, { useEffect } from "react"
import { Col, Container } from "react-bootstrap"
import Marquee from "react-fast-marquee"

import Fade from "react-reveal/Fade"
import SuperOpsLogo from "../assets/images/superops-logo.png"
import CTA from "../components/common/CTA"
import Hero from "../components/common/Hero/Hero"
import SVGIcon from "../components/common/SVGIcon"
import ScrollContainer from "../components/common/Scrolling-container/Scrolling-container"
import Badges from "../components/common/badges/badges"
import Frame from "../components/common/frame"
import Testimonial from "../components/common/testimonial-component/testimonial"
import Navigation from "../components/navigation"
import Slide from "../components/utility/slide_content"
import "../styles/templates/competitorV2.scss"

const CompetitorVersion3 = ({ pageContext }) => {
  const { competitor } = pageContext
  const {
    seo,
    heroSection,
    newfield,
    cardHeading,
    cardSubtext,
    competitorList,
    testimonial,
    superOpsList,
    competitorImages,
    superopsImages,
    components,
    ctaBoxes,
  } = competitor
  let delayCount = 0
  const fiveStar = [1, 1, 1, 1, 1]

  useEffect(() => {
    let container = document.querySelector(".groupImg")
    let square = document.querySelectorAll(".square")
    window.addEventListener("scroll", function() {
      let scrollTop = window.pageYOffset || window.scrollY
      const rect = container?.getBoundingClientRect()
      if (!square.length <= 0 && rect?.top < 900 && rect?.bottom > -100) {
        square[0].style.left = -(scrollTop / 10) + "px"
        square[1].style.left = -(scrollTop / 10) + "px"
        square[2].style.right = -(scrollTop / 10) + "px"
        square[3].style.right = -(scrollTop / 10) + "px"
      }
    })
  }, [])

  const ChildHero = props => {
    return ( 
      <div className="marq-wrap">
        <Marquee pauseOnClick pauseOnHover gradient={false}>
        {components[2]?.testimonialDatas?.map((item, i) => {
            return (
              <Link to={item?.link} target="_blank">
                <Col lg={6} className="marq-child d-flex prelative">
                  <SVGIcon name="g2_logo" className="logo position-absolute" />
                  <div className="p16 review-container w-100 d-flex flex-column justify-content-between">
                    <p className="text-grey review-text m-0">
                      {item.description}
                    </p>
                    <div className="author-star d-flex align-items-center justify-content-between prelative">
                      <div className="d-flex align-items-center cg-10">
                        <img src={item?.image?.url} />
                        <div className="w-100">
                          <p className="review-author fw-600 m-0">{item.name}</p>
                          <div className="d-flex justify-content-between p14">
                            {item?.name != "Nil" ? (
                              <p className="m-0 review-job">
                                {item?.title}
                              </p>
                            ) : (
                              <p className="m-0 invisible review-job">
                                {item?.title}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex star">
                        {fiveStar.map(() => {
                          return <SVGIcon name="star" />
                        })}
                      </div>
                    </div>
                  </div>
                </Col>
              </Link>
            )
          })}
        </Marquee>
      </div>
    )
  }

  return (
    <div className="competitor-new">
      <Frame seo={seo}>
        <header>
          <Navigation
            page="Landing page"
            primaryButtonText="GET STARTED FOR FREE"
            // formOneAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
            // formTwoAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
            signupPopup
            disallowCommonDomains
          />
        </header>

        <section>
          <div
            className="d-flex justify-content-center"
            css={css`
              .marquee-hero {
                background-image: url(${heroSection?.backgroundImage?.url ??
                  "https://media.graphassets.com/BMzRPQW4RvKFbj3SMtjO"});
              }
            `}
          >
            <Hero
              heroClassName="marquee-hero"
              heroCol={10}
              type={heroSection.type}
              background={heroSection.backgroundColor}
              heading={heroSection.heading}
              description={heroSection.content}
              maxDescriptionWidth="48ch"
              formOneAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
              formTwoAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
              competitorImage={heroSection.heroImage.url}
              paddingBottom="36px"
              marginBottom="72px"
              benefits={components[3].benefitsHeading}
              signupFormCtaText="GET STARTED FOR FREE"
              children={<ChildHero />}
              // inputEmail
            />
          </div>
        </section>

        <section
          className={`baner-section mb100 mx-auto ${competitorImages?.length >
            1 && "fixed-height"} `}
        >
          <Container className="Layout-container">
            <Col lg={7} className="mx-auto mb38">
              <Slide delay="200">
                <h2
                  className="text-center fw-bolder mb16"
                  style={{ maxWidth: "66ch" }}
                >
                  {cardHeading[0]}
                </h2>
              </Slide>
              {/* <p className="text-center text-grey">{cardSubtext[0]}</p> */}
            </Col>
            <div
              className={`prelative ${competitorImages?.length > 1 &&
                "groupImg"} mx-auto`}
            >
              {competitorImages?.length <= 1 ? (
                <img src={competitorImages[0]?.url} className="w-100" />
              ) : (
                <>
                  <img src={competitorImages[0]?.url} className="w-100 d-dsk" />
                  <img src={competitorImages[1]?.url} className="w-100 d-mob" />
                  {competitorImages?.slice(2).map((img, i) => (
                    <div
                      className={`position-absolute square only-dsk img${i +
                        1} ${false && "move"}`}
                    >
                      <img src={img?.url} />
                    </div>
                  ))}
                </>
              )}
            </div>
          </Container>
        </section>

        <section
          className="mx-auto black-box"
          style={{
            background: `${heroSection.backgroundColor}50`,
          }}
        >
          <Container className="Layout-container">
            <Col lg={8} className="mx-auto mb50">
              <Slide delay="200">
                <h2 className="text-center fw-bolder text-deep-purple">
                  {testimonial.testimonialSectionHeading}
                </h2>
              </Slide>
            </Col>
            <div className="d-flex flex-wrap item-container mt38">
              {testimonial?.testimonialTitle.map((title, i) => {
                if (delayCount > testimonial?.testimonialTitle?.length) {
                  delayCount = 0
                }
                return (
                  <Col lg={4} className="other-box mb16">
                    <Fade bottom delay={(delayCount + 1) * 100} duration={600}>
                      <span className="dspnone">{delayCount++}</span>

                      <img
                        src={
                          testimonial?.testimonialImage[i]?.url ??
                          "https://media.graphassets.com/djpoFei0TpqNbr2YYnOu"
                        }
                        alt="icon"
                        className="mb24"
                      />
                      <h5 className="fw-bold text-deep-purple">{title}</h5>
                      <p className="p16 mb24 text-grey">
                        {testimonial?.testimonialName[i]}
                      </p>
                    </Fade>
                  </Col>
                )
              })}
            </div>
          </Container>
        </section>

        <div
          className={`section-container-scroll ${components[0].heading.length >
            0 && "mb100"}`}
        >
          <ScrollContainer
            data={newfield}
            topValue="500"
            leftColumn={5}
            rightColumn={6}
          />
        </div>

        {components[0].heading.length > 0 && (
          <section className="comparison-section mx-auto mb100">
            <Container className="Layout-container">
              <Col lg={5} className="mx-auto mb64">
                <Slide delay="200">
                  <h2
                    className="text-center fw-bolder mb16"
                    style={{ maxWidth: "66ch" }}
                  >
                    {components[0].cardName}
                  </h2>

                  <p className="text-center text-grey">
                    {components[0].subtext[0]}
                  </p>
                </Slide>
              </Col>
              <div className="d-flex  mx-auto prelative justify-content-center">
                <Col
                  lg={11}
                  className="d-flex justify-content-evenly align-items-center comparison-card br10"
                >
                  <Col lg={2} className="logos">
                    <div>
                      <img
                        src={SuperOpsLogo}
                        alt="Sposimg"
                        className="h-100 w-100"
                      />
                    </div>
                    <div>
                      <img
                        src={heroSection.heroImage.url}
                        alt="competitorImg"
                        className="h-100 w-100"
                      />
                    </div>
                  </Col>
                  {components[0].heading.map((fval, i) => {
                    const { text, title } = components[0]
                    return (
                      <Col
                        lg={2}
                        className="values text-center d-flex align-items-center"
                      >
                        <h3 className="text-green fw-bolder  m-0 ">{fval}</h3>
                        <div className="d-flex justify-content-center align-items-center rg-8 arrow-wrap">
                          <div>
                            <SVGIcon name="triangle" className="arrowUp" />
                          </div>
                          <p className="text-deep-purple arrow m-0 fw-bolder">
                            {text[i]}
                          </p>
                          <div>
                            <SVGIcon name="triangle" className="arrowDown" />
                          </div>
                        </div>
                        <h3 className="text-grey fw-bolder  m-0 ">
                          {title[i]}
                        </h3>
                      </Col>
                    )
                  })}
                </Col>
              </div>
            </Container>
          </section>
        )}

        <div className="badge-testimonial mx-auto">
          <section className="badge-section">
            <Badges images={superopsImages} />
          </section>

          <section className="testimonial-section mt-5">
            <Slide delay="200">
              <Testimonial type="customer-quote" testimonial={components[1]} />
            </Slide>
          </section>
        </div>

        <section className="cta mt20">
          <CTA
            data={[ctaBoxes[0]]}
            bgPattern
            className="Layout-max"
            maxDescriptionWidth="475px"
            maxBoxWidth="1400px"
            maxRowWidth="1176px"
            lgLeft={5}
            lgRight={7}
            formOneAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
            formTwoAPI={process.env.HUBSPOT_BETA_SIGNUP_API_ENDPOINT}
            signupPopup
            disallowCommonDomains
          />
        </section>
      </Frame>
    </div>
  )
}

export default CompetitorVersion3
